import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import lernaWizardDemoImg from "./lerna-wizard-demo.gif";
import { graphql } from "gatsby";
export const query = graphql`
query ($myComponentPath: String!) {
    mdx(fileAbsolutePath: {eq: $myComponentPath}) {
        frontmatter {
            image {
                publicURL
            }
            imageSmall {
                publicURL
            }
        }
    }
}
`;
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="p" components={components}>{`Quick overview of projects I've had a wonderful time working on...`}</MDXTag>
      <MDXTag name="h2" components={components}><MDXTag name="a" components={components} parentName="h2" props={{
          "href": "https://github.com/webuniverseio/superagent-node-http-timings"
        }}>{`superagent-node-http-timings`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`This is a plugin for superagent package that allows to get http request timing breakdown when requests are initiated on node.js. This is useful for monitoring network quality between your server and service that you're calling via superagent.`}</MDXTag>
      <MDXTag name="h2" components={components}><MDXTag name="a" components={components} parentName="h2" props={{
          "href": "https://github.com/webuniverseio/array-to-object-helper#readme"
        }}>{`array-to-object-helper`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Syntax sugar for destructuring arrays into objects.`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`Promise.resolve([1,2,3,4,5,6,7,8])
.then(([one, two, three, four, five, six, seven, eight]) => 
  ({one, two, three, four, five, six, seven, eight}))
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`turns into`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`Promise.resolve([1,2,3,4,5,6,7,8])
.then(to\`{one, two, three, four, five, six, seven, eight}\`)
`}</MDXTag></MDXTag>
      <MDXTag name="h2" components={components}><MDXTag name="a" components={components} parentName="h2" props={{
          "href": "https://github.com/webuniverseio/lerna-wizard#readme"
        }}>{`lerna-wizard`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Command line wizard for lerna, which simplifies entry into using lerna because commands have clear descriptions.`}<br /></MDXTag>
      <div style={{
        textAlign: 'center',
        marginTop: '1em'
      }}>
  <img src={lernaWizardDemoImg} alt="lerna wizard demo in terminal" />
      </div>
      <MDXTag name="h2" components={components}><MDXTag name="a" components={components} parentName="h2" props={{
          "href": "https://github.com/webuniverseio/ssum#readme"
        }}>{`ssum`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="inlineCode" components={components} parentName="p">{`ssum ~/downloads/file sha512`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`ssum is a command line tool for checksum generation with no dependencies - ideal for people who would like to make sure that checksum of downloaded files is matching checksum as listed on download site.`}</MDXTag>
      <MDXTag name="h2" components={components}><MDXTag name="a" components={components} parentName="h2" props={{
          "href": "https://github.com/webuniverseio/SimpleInstaller"
        }}>{`SimpleInstaller`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Download and/or install programs in similar manner to chocolatey or similar installation managers.`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`crossplatform (tested on windows and debian)`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`downloads files (optional) and runs commands`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`skips already installed programs`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`could be used as a simple task runner`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`could work as a downloader`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`simple and because of that flexible and expendable api`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`tested with 100% coverage`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`uses harmony mode for generators and `}<MDXTag name="inlineCode" components={components} parentName="li">{`npm co`}</MDXTag>{` to manage execution flow`}</MDXTag>
      </MDXTag>
      <MDXTag name="h2" components={components}><MDXTag name="a" components={components} parentName="h2" props={{
          "href": "https://github.com/webuniverseio/sandbox"
        }}>{`Sandbox`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`This little library was designed for modular architectures (`}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.youtube.com/watch?v=b5pFv9NB9fs"
        }}>{`1`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://www.addyosmani.com/scalablejs/"
        }}>{`2`}</MDXTag>{`) to unify API between stand alone modules. It is crossbrowser and crossplatform with 100% coverage.
There are a few simple requirements for it to work effectively:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`your modules should never auto-initialize as they are loading`}<small>Doing this will make sure that your code doesn't depend on the order in which your modules are loading  and you can get a benefit of `'async'` attribute that way, which can increase performance by an order of magnitude.</small></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`there always should be an initialization method`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`you initialize a new instance of sandbox and pass it into initialization calls of your modules`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`List of benefits you'll get from using sandbox:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`unified module initialization api`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Sandbox supports publish/subscribe pattern, and provides additional methods to control permissions for message delivery. So you can control whether one module can communicate with a sibling module, with a sub-module or even with the core`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Sandbox has various caching strategies which will help to deal with asynchronous nature of AMD or ES6 modules`}<small>That way you really stop caring about order in which your modules are initialized, you can always cache events from one module and do lazy initialization when another module loads</small></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`data, passed through Sandbox will become immutable, meaning that you can safely pass objects without fear that one of the modules can modify 'shared' state`}</MDXTag>
      </MDXTag>
      <MDXTag name="h2" components={components}><MDXTag name="a" components={components} parentName="h2" props={{
          "href": "https://github.com/webuniverseio/lodash.dom-traverse"
        }}>{`Lodash traverse`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Native browser support allows you to traverse and select DOM using `}<MDXTag name="inlineCode" components={components} parentName="p">{`'querySelector'`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`'insertAdjacentHTML'`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`'parent'`}</MDXTag>{`, and other API methods. But there are a few cases when it is not flexible/compatible enough and that is why jQuery is around. Lodash traverse is a tiny addon which provides a dozen of useful, chainable methods which in addition to native APIs give you minimalistic, yet ultimate control over your html. In combination with other `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://microjs.com/"
        }}>{`micro libraries`}</MDXTag>{` you can assemble a highly customizable and slim in size library for your favorite projects.`}</MDXTag>
      <MDXTag name="h2" components={components}><MDXTag name="a" components={components} parentName="h2" props={{
          "href": "https://github.com/webuniverseio/simple-permissions"
        }}>{`Simple permissions`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Nothing crazy, just a small library which allows you to add or remove multiple permissions for multiple targets. Crossbrowser and crossplatform with 100% coverage.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {
  "title": "Projects",
  "date": "2015-01-19T20:36:14.000Z",
  "overview": "Wonderful open source projects to ease your development life",
  "image": "./image.jpg",
  "imageSmall": "./imageSmall.jpg",
  "isInTopBar": true
};
    